
@import  '../../styles//variables';

.metric-tittle{
  font-size: small !important;
  color: $primary !important;
  font-weight: $semibold !important;
}

.metric-value{
  font-size: xx-large !important;
  font-weight: $bold !important;
  color: #0A0544 !important;
}

.metric-porcent{
  font-size: small !important;
  font-weight: $semibold !important;
}

.metric-icon{
  font-size: x-small !important;
  font-weight: $semibold !important;
  color: $primary !important;
}

