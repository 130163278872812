@import '../../styles/style.scss';

.text-user-title{
	font-size: large !important;
	font-weight: $semibold !important;
	color: $primary !important;
}

.text-user-rol{
	font-size: small !important;
	font-weight: $semibold !important;
}

.text-user-date{
	font-size: x-small !important;
}