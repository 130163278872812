
@import '../../styles/style.scss';

.tab-tex{
	font-size: large !important;
}

.tab-icon{
	font-size: 35px !important;
	padding: 2px !important;
}

.tab-container {
  display: flex;
  align-items: center; /* Alinea verticalmente en el centro */
}
