@import 'variables';

.app-container{
	background-color: #F6F6F8;
}

.login-container{
	padding: 100px !important;
}

//Contenedor principal
.main-container {
	padding: 10px !important;
	height: 100vh !important;
}

.container-page {
	padding: 30px !important;
	margin-left: 160px !important;
}

.center-elements{
	align-items: center !important;
	align-content: center !important;
}




//Inicia Margenes
//Derecha
.mr-1{
	margin-right: 1rem!important;
}
//Arriba
.mt-01{
	margin-top: 0.5rem!important;
}
.mt-1{
	margin-top: 1rem!important;
}
.mt-2{
	margin-top: 2rem!important;
}
.mt-2n{
	margin-top: -2rem!important;
}
.mt-3{
	margin-top: 3rem!important;
}
.mt-4{
	margin-top: 4rem!important;
}
.mt-5{
	margin-top: 5rem!important;
}
.mt-6{
	margin-top: 6rem!important;
}
.mt-7{
	margin-top: 7rem!important;
}
//Abajo
.mb-1{
	margin-bottom: 1rem!important;
}
.mb-2{
	margin-bottom: 2rem!important;
}
.mb-3{
	margin-bottom: 3rem!important;
}
.mb-4{
	margin-bottom: 4rem!important;
}
.mb-5{
	margin-bottom: 5rem!important;
}

.sider-copy{
	font-size: 12px !important;
	color: gray !important;
	position: absolute;
	bottom: 0px !important;
	left: 0px !important;
	width: 100% !important;
	margin-bottom: 10px !important;
	text-align: center !important;
}


@media (max-width: 768px) {
	.container-page {
		padding: 15px !important;
		height: 100% !important;
	}
	.login-container{
		padding: 70px !important;
	}
	
}

@media (max-width: 480px) {
	.login-container{
		padding: 40px !important;
	}
	
}