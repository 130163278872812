@import '../../styles/style.scss';

.card-media{
	background-color: $primary !important;
	height: 140px !important;
	align-items: center !important;
	align-content: center !important;
	justify-content: center !important;
	display: flex !important;
}

.modal-container-user{
	width: 450px !important;
	height: 450px !important;
}	