@import '../../styles/style.scss';


.toolbar {
    padding: 0 1rem;
    display: flex;
    height: 40px !important;
    background-color: #0A0544 !important;
    justify-content: space-between;
    position: relative;
    z-index:  3 !important;
    .actions-container {
        color: black;
        display: flex;
        .btn-menu {
            margin-left: 1rem;
        }
    }
}

.drawer{
    z-index: 0 !important;
    flex-shrink: 0 !important;
    white-space: 'nowrap' !important;
}

.drawer-list{
    margin-top: 60px !important;
}


