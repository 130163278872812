@import '../../styles/style.scss';

.modal-container{
	padding: 20px !important;
	width: 800px !important;
	height: 700px !important;
}	

.hover-card{
	cursor: pointer;
}

.text-import{
	font-weight: $bold !important;
	color: $primary !important	;
}
