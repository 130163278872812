@import '../../styles/style.scss';

.text-menu-price{
	font-size: small !important;
	font-weight: $semibold !important;
	color: $primary !important;
}

.text-menu-price.size{
	font-size: x-small !important;
} 
.list-topping{
	display: inline-block;
	font-size: xx-small !important;
	line-height: 1 !important;
	color: $primary !important;
	font-weight: $semibold !important;
}

.text-menu-title{
	font-size: small !important;
	font-weight: $bold !important;
	color: $black !important;
}


