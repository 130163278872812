
@import '../../styles/style.scss';

.login-page{
	height: 100vh !important;
	background-position: center !important; 
	background-size: cover !important; 
}


.login-container {
	.logo {
			height: 4rem;
			width: auto;
	}
	.title {
			font-weight: $semibold;
	}
	.title-pink {
			font-weight: $semibold;
			color: $primary;
	}
	.r-side {
			background-color: #ececec;
			min-height: 100vh;
	}
	.btn-login {
			border-radius: 25px;
	}
	.info {
			color: $secondary;
			cursor: pointer !important;
	}
}